import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />

    <section className="feature">
			<div className="container max-width-adaptive-lg margin-y-lg">
				<div className="feature__grid grid gap-md items-center">
          <div className="col-6@md">
						<div className="feature__item">
							<div className="text-component text-xl">
								<h1 className="text-xl">That page doesn't exist!</h1>
                <p>I'm not saying it's gremlins... but I'm not saying it's <em>not</em> gremlins.</p>
                <p className="text-sm">
									<span role="img" aria-hidden="true">👈</span> <Link to="/">Return home</Link>
								</p>
							</div>
						</div>
					</div>
          <div className="col-6@md">
						<div className="feature__item feature__item--media">
							<figure className="blob-radius">
								<Image src="gremlins.jpg" />
							</figure>
						</div>
					</div>
				</div>
			</div>
		</section>

  </Layout>
)

export default NotFoundPage
